import { Stack, Inline } from "~/components/vendorUI";
import { css, cx } from "ui/css";
import LogoWhite from "./logoWhite.svg?react";
import { ReactNode } from "react";
import { NavBar, Footer } from "../navigation";
import { Breadcrumbs, IMatches } from "./Breadcrumbs";
import { CampaignSwitcher } from "./CampaignSwitcher";
import { Link, useMatches } from "@remix-run/react";

export const layoutCSS = css({
  "--layoutWidth": "1200px",
  "--pageSidePadding":
    "max(calc(50vw - calc(var(--layoutWidth) / 2)), token(spacing.16px))",
  marginInline: "var(--pageSidePadding)",
  minHeight: "100dvh",
});

const bleedCSS = css({
  marginInline: "calc(var(--pageSidePadding) * -1)",
  paddingInline: "var(--pageSidePadding)",
});

const headerCSS = css({
  paddingBlock: "24px",
  background: "brandBlue",
  color: "white",
});

const footerCSS = css({ background: "gray.1" });

type VPFLayoutProps = {
  children: ReactNode;
  showBreadcrumbs?: boolean;
  showCampaignSwitcher?: boolean;
};

export function VPFLayout({ children }: VPFLayoutProps) {
  const matches = useMatches() as IMatches[];
  const routeWithCampaignSwitcher = matches.findLast(
    ({ handle }) => !!handle?.campaignType,
  );
  const routeWithAddon = matches.findLast(
    ({ handle }) => !!handle?.campaignSwitcherAddon,
  );
  const campaignSwitcherAddon = routeWithAddon?.handle?.campaignSwitcherAddon;
  const routeWithBreadcrumb = !!matches.findLast(
    ({ handle }) => !!handle?.breadcrumb,
  )?.handle?.breadcrumb;

  return (
    <Stack gap="80px" className={layoutCSS}>
      <Stack gap="48px">
        <Stack gap={0}>
          <Inline align="center" gap="48px" asChild>
            <header className={cx(bleedCSS, headerCSS)}>
              <Link to="/" reloadDocument>
                <LogoWhite height="46px" />
              </Link>
              <NavBar />
            </header>
          </Inline>
          {(routeWithBreadcrumb || routeWithCampaignSwitcher) && (
            <Stack align="start" gap="48px">
              {routeWithBreadcrumb && <Breadcrumbs matches={matches} />}
              {routeWithCampaignSwitcher ? (
                <Inline gap="24px" align="center">
                  <CampaignSwitcher />
                  {campaignSwitcherAddon?.(routeWithAddon?.data)}
                </Inline>
              ) : null}
            </Stack>
          )}
        </Stack>

        <main className={css({ flexGrow: 1 })}>{children}</main>
      </Stack>

      <footer className={cx(bleedCSS, footerCSS)}>
        <Footer />
      </footer>
    </Stack>
  );
}
